import "./HireMe.css"
import React from 'react';
function HireMe(){
    return(
            <a href="#form1">
                <button className="HireMe">
                    <span><b>HIRE ME</b></span>
                </button>
            </a>
    );
}
export default HireMe;